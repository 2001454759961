import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const LogoCard = ({ name, color, image, link }) => {
  return (
    <>
      <div className="col-6 col-md-4 col-lg-3 text-center mb-8 d-flex">
        <div
          className="card card-border shadow-light-lg"
          style={{ borderTopColor: color }}
        >
          <div className="card-body text-center h-100">
            <div className="img-fluid mb-5 w-50 mx-auto">
              <Img fluid={image} alt={name} />
            </div>
            <h4 className="text-uppercase text-gray-900 font-weight-bold">
              {name}
            </h4>
            <a
              href={link}
              className="stretched-link"
              target="_blank"
              rel="noopener noreferrer"
              aria-label={name}
            >{" "}</a>
          </div>
        </div>
      </div>
    </>
  )
}

LogoCard.propTypes = {
  name: PropTypes.node.isRequired,
  color: PropTypes.node,
  image: PropTypes.node,
  link: PropTypes.node,
}

export default LogoCard
