import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LogoCard from "../components/logo-card"
import MoreBlogPosts from "../components/more-blog-posts"
import ActionForm from "../components/action-form"

const Technology = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Image and Video Anonymization Technology | Celantur"
        description="Our core technology is object detection using Deep Learning and deep convolutional networks (CNN) to anonymize images and videos"
      />

      <section
        className="container-fluid pt-8 pt-md-12 pb-8 pb-md-12 overlay overlay-black overlay-80 bg-cover text-center"
        style={{
          background: "url(/images/datacenter.jpg) no-repeat",
          backgroundPositionY: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <h1 className="display-2 font-weight-bold text-white">Our Technology Stack</h1>
          <p className="lead font-weight-bold mb-6 mb-md-8 text-success">State-of-the-art, trusted and versatile</p>
        </div>
      </section>

      <section className="py-8 py-md-5">
        <div className="container text-justify">
          <h2 className="text-success my-3">Object Detection using Convolutional Neural Networks</h2>
          <p className="lead text-muted">Image and Video Anonymization with Deep Learning</p>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-5 col-lg-6 order-md-2 p-6 pb-4">
                <figure>
                  <Img fluid={data.maskrcnn.childImageSharp.fluid} />
                  <figcaption className="text-center">Schematic depiction of Mask R-CNN.</figcaption>
                </figure>
              </div>
              <div className="col-12 col-md-7 col-lg-6 order-md-1 p-0 pb-4">
                <p>
                  Our core technology is object detection using deep convolutional networks (CNN), which in recent years
                  has rapidly evolved from an academic playground to production-ready tools for solving industrial use
                  cases fast and effectively.
                </p>
              </div>
            </div>
          </div>

          <p>
            We combine different architectures such as instance segmentation (Mask R-CNN<sup>[1]</sup> with a FPN
            backbone<sup>[2]</sup>) and keypoint detection (based on Faster R-CNN<sup>[3]</sup>) to anonymize people,
            vehicles, faces or licence plates.
          </p>

          <p>
            As part of the{" "}
            <a href="https://www.nvidia.com/en-us/deep-learning-ai/startups/" target="_blank" rel="noopener noreferrer">
              NVIDIA Inception program
            </a>{" "}
            for AI startups, we have access to support, expertise and training by NVIDIA, the leading AI hardware
            manufacturer.
          </p>

          <ul className="list mb-0">
            <li className="list-item">
              <a className="list-link" style={{ fontSize: "0.8em" }} href="https://arxiv.org/abs/1703.06870">
                [1] He, Kaiming et al. (2018). Mask R-CNN.
              </a>
            </li>
            <li className="list-item">
              <a className="list-link" style={{ fontSize: "0.8em" }} href="https://arxiv.org/abs/1612.03144">
                [2] Lin, Tsung-Yi et al. (2017). Feature Pyramid Networks for Object Detection.
              </a>
            </li>
            <li className="list-item">
              <a className="list-link" style={{ fontSize: "0.8em" }} href="https://arxiv.org/abs/1506.01497">
                [3] Shaoqing Ren et al. (2016). Faster R-CNN: Towards Real-Time Object Detection with Region Proposal
                Networks.
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section className="py-8 py-md-5">
        <div className="container text-justify">
          <h2 className="text-success">Cloud, Docker and APIs</h2>
          <p className="lead text-muted">Scalable and Flexible Anonymization Pipeline</p>

          <p>
            We have set up our scalable and flexible anonymization pipelines in GDPR-compliant data centres around
            Europe. Available also as a Docker container, it can be installed on a local machine, private or public
            cloud infrastructure.
          </p>
          <div className="row">
            <LogoCard
              name="Python"
              color="#75a8d3"
              image={data.logoPython.childImageSharp.fluid}
              link="https://www.python.org/"
            />
            <LogoCard
              name="Amazon Web Services"
              color="#f8991d"
              image={data.logoAws.childImageSharp.fluid}
              link="https://aws.amazon.com/"
            />
            <LogoCard
              name="PyTorch"
              color="#ee4c2c"
              image={data.logoPytorch.childImageSharp.fluid}
              link="https://pytorch.org/"
            />
            <LogoCard
              name="NVIDIA"
              color="#76b900"
              image={data.logoNvidia.childImageSharp.fluid}
              link="https://www.nvidia.com/en-us/deep-learning-ai/"
            />
          </div>
        </div>
      </section>

      <MoreBlogPosts caption="Latest Technology Blog Posts" posts={data} />

      <section className="py-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-auto">
              <Link to="/contact/" className="btn btn-success event-contact">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8">
        <ActionForm source="technology" />
      </section>
    </Layout>
  )
}

export default Technology

export const logo = graphql`
  fragment logoTechnology on File {
    childImageSharp {
      fluid(maxHeight: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: ["machine learning", "computer vision"] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            tags
            path
            teaser
            date
          }
        }
      }
    }
    logoPython: file(relativePath: { eq: "python.png" }) {
      ...logoTechnology
    }
    logoAws: file(relativePath: { eq: "aws-logo.png" }) {
      ...logoPartners
    }
    logoNvidia: file(relativePath: { eq: "nvidia.png" }) {
      ...logoPartners
    }
    logoPytorch: file(relativePath: { eq: "pytorch.png" }) {
      ...logoPartners
    }
    maskrcnn: file(relativePath: { eq: "mask-r-cnn.png" }) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
